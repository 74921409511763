import { css, cx } from 'linaria';
import React from 'react';
const containerStyles = "c1onf3o9";

const Drawer = ({
  right = false,
  size = 320,
  isOpen = false,
  children
}) => {
  const initialPosition = right // this is for server side rendering since popmotion doesn't support it
  ? {
    transform: `translateX(${isOpen ? 0 : size}px)`,
    right: 0
  } : {
    transform: `translateX(-${isOpen ? 0 : size}px)`,
    left: 0
  };
  return React.createElement("div", {
    className: cx(containerStyles, isOpen ? 'open' : 'closed', right ? 'right' : 'left'),
    style: Object.assign({
      width: `${size}px`
    }, initialPosition)
  }, children);
};

export default Drawer;

require("../../../../../.linaria-cache/node_modules/@jetshop/ui/Modal/Drawer/Drawer.linaria.css");