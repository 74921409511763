var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import { css, cx } from 'linaria';
import React, { useContext, useRef } from 'react';
import { withFlightUI } from '../withFlightUI';
export const DropdownMenuContext = React.createContext(undefined);
"dw7sw52";
const styles = "sbsycch";
export const DropdownMenu = React.memo(function DropdownMenu(_a) {
  var {
    initialOpen = false,
    unstyled,
    children,
    className
  } = _a,
      rest = __rest(_a, ["initialOpen", "unstyled", "children", "className"]);

  const [isOpen, setIsOpen] = React.useState(initialOpen);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const itemRefs = useRef([]);
  const value = React.useMemo(() => {
    return {
      isOpen,
      setIsOpen,
      selectedIndex,
      setSelectedIndex,
      itemRefs
    };
  }, [isOpen, selectedIndex]);
  return withFlightUI(React.createElement(DropdownMenuContext.Provider, {
    value: value
  }, typeof children === 'function' ? children({
    isOpen,
    setIsOpen
  }) : children), Object.assign({
    as: 'div',
    'data-flight-dropdown': '',
    'data-flight-dropdown-open': isOpen,
    unstyled,
    className: cx(styles, className)
  }, rest));
});
DropdownMenu.displayName = 'withFlightUI(DropdownMenu)';
export function useDropdownMenu() {
  const {
    isOpen,
    setIsOpen
  } = useContext(DropdownMenuContext);
  return {
    isOpen,
    setIsOpen
  };
}

require("../../../../.linaria-cache/node_modules/@jetshop/ui/DropdownMenu/DropdownMenu.linaria.css");